export default class UsersIndexPage {
    constructor() {
        if (!document.querySelector('#users-index-page')) {
            return;
        }
        this.init();
    }

    init() {
        this.setupDeleteButtons();
    }

    setupDeleteButtons() {
        const deleteForms = document.querySelectorAll('.delete-form');

        deleteForms.forEach((form) => {
            const deleteButton = form.querySelector('.delete-button');
            const confirmationDialog = form.querySelector('.confirmation-dialog');
            const cancelButton = form.querySelector('.cancel-button');

            deleteButton.addEventListener('click', () => {
                confirmationDialog.classList.remove('hidden');
            });

            cancelButton.addEventListener('click', () => {
                confirmationDialog.classList.add('hidden');
            });
        });
    }
}
