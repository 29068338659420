export default class FeatureStatisticsMetrics {
    constructor() {
        let featureStatisticsUsage = document.querySelector('#feature-statistics-usage');
        if (!featureStatisticsUsage) {
            return;
        }
        this.featureStatisticsUsage = featureStatisticsUsage; // Store reference for later use
        this.init();
    }

    init() {
        // Find all tab buttons
        const tabButtons = this.featureStatisticsUsage.querySelectorAll('.tab-button');

        // Add click event listeners to tab buttons
        tabButtons.forEach((button) => {
            button.addEventListener('click', (event) => {
                const targetContentId = button.getAttribute('id').replace('tab-', 'content-');
                this.switchTab(targetContentId, button);
            });
        });
    }

    switchTab(contentId, tabElement) {
        // Find all tab content panes and hide them
        const tabContents = this.featureStatisticsUsage.querySelectorAll('.tab-pane');
        tabContents.forEach((content) => {
            content.classList.add('hidden');
        });

        // Remove 'active' class from all tab buttons
        const tabButtons = this.featureStatisticsUsage.querySelectorAll('.tab-button');
        tabButtons.forEach((button) => {
            button.classList.remove('active', 'bg-gray-100', 'dark:bg-gray-800');
        });

        // Show the selected tab content
        const targetContent = this.featureStatisticsUsage.querySelector(`#${contentId}`);
        if (targetContent) {
            targetContent.classList.remove('hidden');
        }

        // Add 'active' class to the clicked tab button
        tabElement.classList.add('active', 'bg-gray-100', 'dark:bg-gray-800');
    }
}
